@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

/*Kod do weryfikacji*/

.home,
.reports,
.products {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  background-color: #000;
  color: #fff;
}

/* Navbar CSS */
.navbar {
  background-color: #ffffff;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: #000;
}

.menu-bars.active {
  visibility: hidden;
}

.navMenu {
  background-color: #ffffff;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 3;
}

.navMenu.active {
  left: 0;
  transition: 450ms;
  opacity: 95%;
  z-index: 3;
}

.navText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.navText a {
  text-decoration: none;
  color: #151414;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.navText a:hover {
  background-color: #000000;
  color: #fff;
}

.navMenuItems {
  width: 100%;
}

.navbarToggle {
  background-color: #ffffff;
  width: 100%;
  height: 80px;
  padding-right: 5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

span {
  margin-left: 16px;
}

/*Footer CSS*/
footer {
  position: relative;
  left: 0;
  top: 80vh;
  width: 100%;
  background-color: rgb(70, 70, 70);
  color: white;
  text-align: center;
}

.footerInfo {
  display: flex;
  justify-content: space-evenly;
  align-content: space-evenly;
  padding: 1% 0;
 
}

.footerCopyright {
  background-color: rgba(161, 161, 161, 0.863);
  display: flex;
  justify-content: space-evenly;
  padding: 0.8% 0;
}

.footerCopyrightText p {
  font-style: italic;
  font-size: 0.9em;

}

/*Logo CSS*/

.navbar img {
  display: flex;
  justify-content: center;
  margin: auto;
}

