@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Roboto+Slab&family=Roboto:wght@500&display=swap');

.slidingGallery {
    background-image: url("./image-002.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: fixed;
    width: 100%;
    max-width: 100%;
    height: 1000px;
    z-index: -1;
}
.slidingGallery h1 {
    font-size: 4em;
    position: relative;
    display: flex;
    justify-content: center;
    margin: auto;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-family: 'Roboto', sans-serif;
    font-family: 'Roboto Slab', serif;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    animation-name: animationOfH1;
    animation-duration: 2s;
    animation-timing-function: ease-out;
}


@keyframes animationOfH1 {
    from {
        opacity: 0%;
        top: 50px;
    }
    to {
        opacity: 100%;
        top: 0;
    }
}

.descriptionText {
    margin: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    top: 80vh;
    width: 100%;
    height: 75vh;
    background-color: darkgrey;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 2;
    font-size: 1vw;
}

.descriptionText a{
    color: red;
    text-underline-position: below;
}

.descriptionText img {
    position: relative;
    height: 20em;
    width: 43%;
    bottom: -100px;
    left: 170px;
    z-index: 2;
    border-radius: 15px;
}

.TextContainer {
    position: relative;
    margin: 0;
    block-size: fit-content;
    border-radius: 20px;
    background-color: white;
    color: black;
    width: 40%;
    padding: 20px;
    left: 200px;
    z-index: 3;
    font-size: 2vh;
    top: 80px;
    

}
.TextContainer ul {
    margin-left: 30px;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
}
.page-wrapper {
    box-sizing: border-box;
}

@media screen and (max-width: 1200px) {
    .TextContainer  {
      width: 100vw;
      left:0;
      padding: 10px;
      
    }
    .descriptionText img {
        width: 80vw;
        height: 38em;
        z-index: 2;
        margin: auto;
        bottom: -5px;
        align-items: center;
        left: 0;
    }
    .descriptionText {
        height: 80vh;
    }
}

