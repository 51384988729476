@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Roboto+Slab&family=Roboto:wght@500&display=swap');
h2 {
    margin: 15vh auto 5vh auto;
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 80vh;
    background-color: rgb(214, 214, 214);
    border-radius: 10px;
    width: 50%;
    font-size: 30px;
    text-align: center;
    padding: 10px;
}

.slidingGallery {
    background-image: url("./image-002.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: fixed;
    width: 100%;
    max-width: 100%;
    height: 1000px;
    z-index: -1;
}

.containerStyles {
    width: 1100px;
    height: 850px;
    margin: 8vh auto;
    position: relative;
    bottom: 80vh;
}

.containerText {
    margin: 5vh 20vw;
    font-size: 2vw;
    font-family: 'Poppins', sans-serif;
    font-family: 'Roboto', sans-serif;
    font-family: 'Roboto Slab', serif;
    background-color: cadetblue;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    bottom: 43vh;
}

.containerText.active {
    margin: 5vh 20vw;
    font-size: 2vw;
    font-family: 'Poppins', sans-serif;
    font-family: 'Roboto', sans-serif;
    font-family: 'Roboto Slab', serif;
    background-color: rgb(247, 120, 16);
    border-radius: 20px;
    padding: 20px;
    position: relative;
    bottom: 43vh;
    animation-name: scrollAnimation;
    animation-duration: 2s;
    animation-iteration-count: 1;

}

.containerText ul {
    margin-left: 50px;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
}

@media screen and (max-width: 900px) {
    .containerStyles  {
      width: 90vw;
      height: 40vh;
      
    }
}

.containerAll {
    position: relative;
    top: 80vh;
}

@keyframes scrollAnimation {
    from{
        opacity: 0;
        transform: translateX(-100px);
    }
    to{
        opacity: 100%;
        transform: translateX(0);
    }
}
