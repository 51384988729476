.containerOffer h1{
    position: relative;
    top: 20vh;
    text-align: center;
    margin: 5vh auto;
    padding: 10px;
    font-size: 5vh;
    background-color: rgb(247, 120, 16);
    width: 40%;
    border-radius: 10px;
    animation: animationOfContainerOffer 2s 1;

}

.slidingGallery {
    background-image: url("./image-002.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: fixed;
    width: 100%;
    max-width: 100%;
    height: 1000px;
    z-index: -1;
}

.containerOffer .animationOffer {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: -45vh;
    align-content: center;
    flex-wrap: wrap;
    margin: auto;
    padding: 15px;
    width: 70%;
    height: 150vh;
    font-size: 3vh;
    background-color: rgb(247, 120, 16);
    border-radius: 10px;
    padding: 15px;
    animation: animationOfContainerOffer 2s 1;


}

.containerOffer .animationOffer p {
    position: absolute;
    top: 5vh;
    margin-left: 15px;
}

.containerOffer .animationOffer .animationMechanicOffer {
    position: absolute;
    top: 20vh;
    background-color: rgb(214, 214, 214);
    border-radius: 10px;
    font-size: 2.5vh;
    margin-right: 15px;
    margin-left: 15px;
    padding: 10px;
    animation: animationOfMechanicsAndTiresOffer 4s 1;
}

.containerOffer .animationOffer .animationMechanicOffer h2 {
    margin: 5vh auto 5vh auto;
    background-color: rgb(241, 150, 64);
    bottom: 0;
}

.containerOffer .animationOffer .animationTiresOffer h2 {
    margin: 5vh auto 5vh auto;
    background-color: rgb(241, 150, 64);
    bottom: 0;
    
}

.containerOffer .animationOffer .animationMechanicOffer ul ol {
    margin-left: 20px; 
    margin-top: 15px;
    font-size: 2vh;
}

.containerOffer .animationOffer .animationTiresOffer {
    position: absolute;
    top: 90vh;
    background-color: rgb(214, 214, 214);
    border-radius: 10px;
    font-size: 2.5vh;
    margin-right: 15px;
    margin-left: 15px;
    padding: 10px;
    animation: animationOfMechanicsAndTiresOffer 4s 1;
}

.containerOffer ul {
    margin-left: 50px;
    margin-top: 0.8vw;
    margin-bottom: 0.8vw;
}

@keyframes animationOfContainerOffer {
    from {
        opacity: 0%;
        left: 50px;
    }
    to {
        opacity: 100%;
        left: 0;
    }
}

@keyframes animationOfMechanicsAndTiresOffer {
    from {
        opacity: 0%;
    }
    to {
        opacity: 100%;
    }
}

@media screen and (max-width: 1000px) {
    .containerOffer .animationOffer {
        height: 300vh;
    }

    .containerOffer .animationOffer .animationMechanicOffer h2 {
        width: fit-content;
    }
    
    .containerOffer .animationOffer .animationTiresOffer h2 {
        width: fit-content;
    }

    .containerOffer .animationOffer .animationTiresOffer {
        top: 168vh;
        height: fit-content;
    }
}
